import React from "react";
import useWindowDimensions from "../hooks/use-window-dimensions";
import { isExtraSmallBreakPoint, isSmallBreakPoint } from "../../utils/utils";
import { Link } from "gatsby";
import ShareIcon from "../../images/svg/share.svg";
import ArrowRightIcon from "../../images/svg/arrow-right.svg";
import ArrowLeftIcon from "../../images/svg/arrow-left.svg";

const TitleAndControls = ({
  title,
  previousPostID,
  nextPostID,
  previousURL,
  nextURL,
  setModalOpen,
}) => {
  const { windowWidth } = useWindowDimensions();
  const titleAndControlsClass = isExtraSmallBreakPoint(windowWidth)
    ? "title-and-controls"
    : "title-and-controls";

  const mobileTitleAndControls = (
    <>
      {previousPostID && (
        <div className="post-control left">
          <Link to={previousURL}>
            <ArrowLeftIcon />
          </Link>
        </div>
      )}
      <h1 className="post-title">{title}</h1>
      {nextPostID && (
        <div className="post-control right">
          <Link to={nextURL}>
            <ArrowRightIcon />
          </Link>
        </div>
      )}
    </>
  );

  const desktopTitleAndControls = (
    <>
      <h1 className="post-title">{title}</h1>
      <div className="post-control-wrapper">
        <span
          className="share-icon"
          onClick={() => setModalOpen(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setModalOpen(true);
            }
          }}
          role="button"
          aria-label="Share"
          tabIndex={0}
        >
          <ShareIcon />
        </span>
        {previousPostID && (
          <div className="post-control left">
            <Link to={previousURL}>
              <ArrowLeftIcon />
            </Link>
          </div>
        )}
        {nextPostID && (
          <div className="post-control right">
            <Link to={nextURL}>
              <ArrowRightIcon />
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const currentTitleAndControls =
    isExtraSmallBreakPoint(windowWidth) || isSmallBreakPoint(windowWidth)
      ? mobileTitleAndControls
      : desktopTitleAndControls;

  return <div className={titleAndControlsClass}>{currentTitleAndControls}</div>;
};

export default TitleAndControls;
