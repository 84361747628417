import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";

import InfiniteHitsSimple from "./infiniteHitsSimple";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_ONLY_API_KEY
);

const indexes = {
  ASCENDING: "designburger_date_asc",
  DECENDING: "designburger_date_desc",
};

const Feed = () => {
  const indexName = indexes.DECENDING;

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <h3 className="more-projects">More Projects</h3>
      <div className="instagram-feed simple">
        <Configure hitsPerPage={9} />
        <InfiniteHitsSimple />
      </div>
    </InstantSearch>
  );
};

export default Feed;
