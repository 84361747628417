import React from "react";
import Button from "../button/button";
import { motion } from "framer-motion";

const Modal = ({ setModalOpen, children }) => {
  return (
    <motion.div
      key="modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="modal"
      onClick={() => setModalOpen(false)}
    >
      <div
        className="modal-container"
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className="modal-ribbon">
          <h3 className="modal-title">Share</h3>
          <Button onClick={() => setModalOpen(false)} text="" type="closeSm" />
        </div>
        <div className="modal-content center">{children}</div>
      </div>
    </motion.div>
  );
};

export default Modal;
