import React from "react";

const Button = (props) => {
  const { onClick, text, type } = props;
  let buttonClasses = "button";

  if (type === "contact") {
    buttonClasses = "button contact";
  } else if (type === "closeLg") {
    buttonClasses = "button close closeLg";
  } else if (type === "closeSm") {
    buttonClasses = "button close closeSm";
  }

  return (
    <button className={buttonClasses} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
