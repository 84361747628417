import React, { Component } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import ItemDescription from "./item-description/item-description";
import Hit from "./hit";

class InfiniteHits extends Component {
  constructor(props) {
    super(props);
    this.feedRef = React.createRef();
    this.state = {
      showOverlay: false,
      overlayImage: false,
      overlayHeight: 0,
      overlayTop: 0,
      overlayCaption: "",
      overlayNodeID: "",
      replaceOverlay: false,
      mobileCaptionOpenNodeID: -1,
    };
  }

  openOverlay = (item, image, caption, objectID) => {
    if (this.state.showOverlay) {
      this.setState({
        ...this.state,
        showOverlay: false,
        overlayHeight: item.current.getBoundingClientRect().height,
        overlayTop: this.getOffsetY(item),
        overlayImage: image,
        overlayCaption: caption,
        overlayNodeID: objectID,
        replaceOverlay: true,
      });
    } else if (this.state.replaceOverlay) {
      this.setState({
        ...this.state,
        replaceOverlay: false,
        showOverlay: true,
      });
    } else {
      this.setState({
        ...this.state,
        overlayImage: image,
        overlayCaption: caption,
        overlayHeight: item.current.getBoundingClientRect().height,
        overlayTop: this.getOffsetY(item),
        overlayNodeID: objectID,
        showOverlay: true,
      });
    }
  };

  closeOverlay = () => {
    this.setState({
      ...this.state,
      showOverlay: false,
    });
  };

  getOffsetY = (item) => {
    const feedRect = this.feedRef.current.getBoundingClientRect();
    const itemRect = item.current.getBoundingClientRect();
    return itemRect.top - feedRect.top;
  };

  handleResize = () => {
    this.setState({
      ...this.state,
      showOverlay: false,
    });
  };

  mobileCaptionOpened = (nodeID) => {
    //const currentScrollTop = window.scrollY;
    const previousOpenMobileCaption = document.getElementById(
      "mobile-caption-" + this.state.mobileCaptionOpenNodeID
    );
    const nextOpenMobileCaption = document.getElementById(
      "feed-item-" + nodeID
    );

    let shouldScroll = false;

    if (previousOpenMobileCaption != null && nextOpenMobileCaption != null) {
      shouldScroll =
        nextOpenMobileCaption.dataset.index >
        previousOpenMobileCaption.dataset.index;
    }

    if (previousOpenMobileCaption != null && shouldScroll) {
      //const height = previousOpenMobileCaption.offsetHeight;
      // window.scrollTo(0, currentScrollTop - height);
    }

    this.setState({
      ...this.state,
      mobileCaptionOpenNodeID: nodeID,
    });
  };

  componentDidMount() {
    // Handle resize
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Handle resize
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { hits, hasMore, refineNext } = this.props;

    return (
      <div className="feed" ref={this.feedRef}>
        <ItemDescription
          close={this.closeOverlay}
          open={this.openOverlay}
          replace={this.state.replaceOverlay}
          visible={this.state.showOverlay}
          image={this.state.overlayImage}
          caption={this.state.overlayCaption}
          height={this.state.overlayHeight}
          top={this.state.overlayTop}
          nodeID={this.state.overlayNodeID}
        />
        {hits.map((hit, i) => {
          return (
            <Hit
              hit={hit}
              key={i}
              openOverlay={this.openOverlay}
              mobileCaptionOpened={this.mobileCaptionOpened}
              mobileCaptionOpenNodeID={this.state.mobileCaptionOpenNodeID}
            />
          );
        })}
        <button
          disabled={!hasMore}
          onClick={refineNext}
          className="default-button"
        >
          Show more
        </button>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
