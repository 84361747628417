import * as React from "react";
import { graphql } from "gatsby";
import { getCaptionComponents } from "../utils/getCaptionComponents";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AnimatePresence } from "framer-motion";
import {
  hasWindow,
  isExtraSmallBreakPoint,
  isSmallBreakPoint,
  trimEndNonAlphanumeric,
} from "../utils/utils";

import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";
import Share from "../images/svg/share.svg";
import InstagramFeedSimple from "../components/instagram-feed/instagramFeedSimple";
import useWindowDimensions from "../components/hooks/use-window-dimensions";
import ShareButtons from "../components/ui/button/share/share-buttons";
import Modal from "../components/ui/modals/modal";
import useNoScroll from "../components/hooks/use-no-scroll";
import TitleAndControls from "../components/instagram-post/title-and-controls";
import Divider from "../components/ui/dividers/divider";
import SeeMoreFeed from "../components/instagram-feed/see-more-feed";

const InstagramPost = ({ data, pageContext }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const previousURL = "/" + pageContext.previousPath;
  const nextURL = "/" + pageContext.nextPath;

  const captionComponents = getCaptionComponents(data.instagramContent.caption);
  const { windowWidth } = useWindowDimensions();
  useNoScroll(modalOpen);

  // console.log("tags:", pageContext.tagsRegex);
  // console.log("id:", pageContext.id);
  // console.log(data);
  // console.log(data.allInstagramContent);

  return (
    <Layout>
      <Seo title={captionComponents.title} />
      <div className="padded instagram-post">
        <TitleAndControls
          title={captionComponents.title}
          previousPostID={pageContext.previousID}
          nextPostID={pageContext.nextID}
          previousURL={previousURL}
          nextURL={nextURL}
          setModalOpen={setModalOpen}
        />

        <div className="caption">
          <div className="left">
            {captionComponents.body.details.map((item, index) => {
              if (0 === index) {
                return (
                  <p key={index}>
                    <b>{item}</b>
                  </p>
                );
              }
              return <p key={index}>{item}</p>;
            })}
          </div>
          <div className="right">
            <p>
              {captionComponents.body.credits.map((string, index) => {
                if (string.charAt(0) === "@") {
                  return (
                    <span className="highlight instagram-link" key={index}>
                      <a
                        href={
                          "https://www.instagram.com/" +
                          trimEndNonAlphanumeric(string.substring(1)) +
                          "/"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        {trimEndNonAlphanumeric(string)}{" "}
                      </a>
                    </span>
                  );
                }
                return string;
              })}
            </p>
            {(isExtraSmallBreakPoint(windowWidth) ||
              isSmallBreakPoint(windowWidth)) && (
              <div className="share-icon-wrapper">
                <span
                  className="share-icon"
                  onClick={() => setModalOpen(true)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setModalOpen(true);
                    }
                  }}
                  role="button"
                  aria-label="Share"
                  tabIndex={0}
                >
                  <Share />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="images">
          {data.instagramContent.album &&
            data.instagramContent.album.map((item, index) => {
              if (item.media_type !== "VIDEO") {
                return (
                  <GatsbyImage
                    image={getImage(item.localFile)}
                    alt=""
                    className="instagram-post-image-wrapper"
                    imgClassName="instagram-post-image"
                    key={index}
                  />
                );
              }
              return null;
            })}
        </div>

        {data.allInstagramContent.edges.length > 0 && (
          <>
            <Divider />

            <SeeMoreFeed
              tags={captionComponents.tags}
              nodeID={data.instagramContent.id}
              relatedPosts={data.allInstagramContent}
            />
          </>
        )}

        <Divider />
        <InstagramFeedSimple minHitsPerPage={16} />
      </div>
      <AnimatePresence>
        {modalOpen && hasWindow && (
          <Modal setModalOpen={setModalOpen}>
            <ShareButtons
              url={window.location.href}
              title={captionComponents.title}
              description={"#DesignBurger"}
            />
          </Modal>
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default InstagramPost;

export const query = graphql`
  query ($id: String!, $tagsRegex: String!) {
    instagramContent(id: { eq: $id }) {
      id
      caption
      album {
        media_type
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    allInstagramContent(
      filter: { caption: { regex: $tagsRegex }, id: { ne: $id } }
    ) {
      edges {
        node {
          id
          caption
          timestamp
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, height: 1200, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
