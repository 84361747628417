import React, { useState, useEffect } from "react";
import ItemDescription from "./item-description/item-description";
import FeedItem from "./feed-item/feed-item";
import { hasWindow } from "../../utils/utils";

const SeeMoreFeed = ({ tags, nodeID, relatedPosts }) => {
  const allRelatedPosts = relatedPosts.edges;
  const postsToShow = 3;
  const regex = new RegExp(/^[^•]+$/);
  const relatedPostsFiltered = allRelatedPosts.filter((post) =>
    regex.test(post.node.caption)
  );

  //Below allows related posts to be loaded as pages - currently disabled as not required by Design Burger

  //const postsPerPage = 3;

  // const [list, setList] = useState([...allRelatedPosts.slice(0, postsPerPage)]);
  // const [loadMore, setLoadMore] = useState(false);
  // const [hasMore, setHasMore] = useState(allRelatedPosts.length > postsPerPage);
  // const handleLoadMore = () => {
  //   setLoadMore(true);
  // };

  // useEffect(() => {
  //   if (loadMore && hasMore) {
  //     const currentLength = list.length;
  //     const isMore = currentLength < allRelatedPosts.length;
  //     const nextResults = isMore
  //       ? allRelatedPosts.slice(currentLength, currentLength + postsPerPage)
  //       : [];
  //     setList([...list, ...nextResults]);
  //     setLoadMore(false);
  //   }
  // }, [loadMore, hasMore]); //eslint-disable-line

  // useEffect(() => {
  //   const isMore = list.length < allRelatedPosts.length;
  //   setHasMore(isMore);
  // }, [list]); //eslint-disable-line

  // Custom feed stuff
  const feedRef = React.createRef();
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImage, setOverlayImage] = useState(false);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const [overlayTop, setOverlayTop] = useState(0);
  const [overlayCaption, setOverlayCaption] = useState("");
  const [overlayNodeID, setOverlayNodeID] = useState("");
  const [replaceOverlay, setReplaceOverlay] = useState(false);
  const [mobileCaptionOpenNodeID, setMobileCaptionOpenNodeID] = useState(-1);

  const openOverlay = (item, image, caption, objectID) => {
    if (showOverlay) {
      setShowOverlay(false);
      setOverlayHeight(item.current.getBoundingClientRect().height);
      setOverlayTop(getOffsetY(item));
      setOverlayImage(image);
      setOverlayCaption(caption);
      setOverlayNodeID(objectID);
      setReplaceOverlay(true);
    } else if (replaceOverlay) {
      setReplaceOverlay(false);
      setShowOverlay(true);
    } else {
      setOverlayImage(image);
      setOverlayCaption(caption);
      setOverlayHeight(item.current.getBoundingClientRect().height);
      setOverlayTop(getOffsetY(item));
      setOverlayNodeID(objectID);
      setShowOverlay(true);
    }
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const getOffsetY = (item) => {
    const feedRect = feedRef.current.getBoundingClientRect();
    const itemRect = item.current.getBoundingClientRect();
    return itemRect.top - feedRect.top;
  };

  const mobileCaptionOpened = (nodeID) => {
    //const currentScrollTop = window.scrollY;
    const previousOpenMobileCaption = document.getElementById(
      "mobile-caption-" + mobileCaptionOpenNodeID
    );
    const nextOpenMobileCaption = document.getElementById(
      "feed-item-" + nodeID
    );

    let shouldScroll = false;

    if (previousOpenMobileCaption != null && nextOpenMobileCaption != null) {
      shouldScroll =
        nextOpenMobileCaption.dataset.index >
        previousOpenMobileCaption.dataset.index;
    }

    if (previousOpenMobileCaption != null && shouldScroll) {
      //const height = previousOpenMobileCaption.offsetHeight;
      // window.scrollTo(0, currentScrollTop - height);
    }

    setMobileCaptionOpenNodeID(nodeID);
  };

  useEffect(() => {
    if (hasWindow()) {
      const handleResize = () => {
        setShowOverlay(false);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // console.log("current open", mobileCaptionOpenNodeID);

  return (
    <div className="see-more-container">
      <h3 className="tags">
        See more work from{" "}
        {tags.map((tag, i) => (
          <span key={"tag-wrapper" + i}>
            {i > 0 && " & "}
            <span className="tag">{tag}</span>
          </span>
        ))}
      </h3>
      <div className="instagram-feed">
        <div className="feed" ref={feedRef}>
          <ItemDescription
            close={closeOverlay}
            open={openOverlay}
            replace={replaceOverlay}
            visible={showOverlay}
            image={overlayImage}
            caption={overlayCaption}
            height={overlayHeight}
            top={overlayTop}
            nodeID={overlayNodeID}
          />
          {relatedPostsFiltered.slice(0, postsToShow).map((post, i) => (
            <FeedItem
              key={post.node.id}
              image={post.node.localFile}
              openOverlay={openOverlay}
              caption={post.node.caption}
              nodeID={post.node.id}
              mobileCaptionOpened={mobileCaptionOpened}
              mobileCaptionOpen={
                mobileCaptionOpenNodeID === post.node.id ? true : false
              }
              index={i}
            />
          ))}
        </div>
        {/* {hasMore ? (
          <button onClick={handleLoadMore}>Load More</button>
        ) : (
          <p>No more results</p>
        )} */}
      </div>
    </div>
  );
};

export default SeeMoreFeed;
